import React, { useState, useEffect } from 'react';
import s from './statusProcessingOrders.module.scss';
import { Box } from '@material-ui/core';
 
import { BaseFilter } from '../../baseFilter/baseFilter';
import { fetchDataFilters } from '../../baseFilter/apiFetchDataFilters';
import { TableOrderManager } from '../tableOrderManager/tableOrderManager';
import { updateOrders } from './updateOrdersApi';
import { Loader } from '../../Loader/Loader';

export const StatusProcessingOrders = ({ ...props }) => {
  const { AuthUser, statuses } = props;
 
  let manager_id =
    AuthUser.role_code !== 'ap'
      ? AuthUser.manager_profile.id
      : '';

  const initValue = { 
    company_id: '',
    direction_id: '',
    // date: '',
    date_to: '',
    date_from: '',
    statuses:
      statuses === 'archive'
        ? ['finished', 'declined', 'unactive']
        : [statuses],
    page_number: 1,
    page_size: 5,
    manager_profile_id: manager_id,
  };

  const tableValue = {
    orders: [],
  };
  const [filterValue, setFilterValue] = useState(initValue);
  const [filterData, setFilterData] = useState({});
  const [tableData, setTablerData] = useState(tableValue);
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState({ isOpen: false }); // modal розцінка

  useEffect(() => {
    if (filterValue.statuses[0] !== statuses) {
      setFilterValue({
        ...filterValue,
        statuses:
          statuses === 'archive'
            ? ['finished', 'declined', 'unactive']
            : [statuses],
      });
    }
  }, [statuses]);

  useEffect(() => {
    setIsLoading(true);
    Promise.resolve(
      fetchDataFilters({
        manager_profile_id: manager_id,
        current_user_id: AuthUser.id,
        statuses:
          statuses === 'archive'
            ? ['finished', 'declined', 'unactive']
            : filterValue.statuses,
      }),
    ).then((result) => {
       
      setFilterData(result);
      setIsLoading(false);
    });
  }, [filterValue.statuses]);

  useEffect(() => {
    updateOrders(filterValue, setTablerData);
  }, [filterValue]);
 

  return (
    <>
      <Box className={s.tableMain}>
        <Box className="Header">
          <BaseFilter
            defaultValue={initValue}
            filterData={filterData}
            setFilterData={setFilterData}
            filterValue={filterValue}
            changefilterValue={setFilterValue}
          ></BaseFilter>
        </Box>
        <Box>
          <>
            {isLoading ? (
              <Box className={`${s.wrapLoad} ${s.wrapIsLoading}`}>
                <Box className={s.loadText}>
                  Завантаження замовлень
                </Box>
                <Box className={s.wrapLoaderBox}>
                  <Loader className={s.loader}></Loader>
                </Box>
              </Box>
            ) : (
              <>
                {tableData.orders < 1 ? (
                  <Box className={s.wrapLoad}>
                    <Box className={s.loadText}>
                      Замовлень з даним статусом не знайдено
                    </Box>
                  </Box>
                ) : (
                  <TableOrderManager
                    filterValue={filterValue}
                    tableData={tableData}
                    setTablerData={setTablerData}
                    setFilterValue={setFilterValue}
                    AuthUser={AuthUser}
                    statuses={statuses}
                  ></TableOrderManager>
                )}
              </>
            )}
          </>
        </Box>
      </Box>
    </>
  );
};
