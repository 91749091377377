export const publicRoutes = {
  LOGIN: '/auth/login',
  REGISTER: '/auth/register',
  FORGOT_PASSWORD: '/auth/forgot-password',
};
export const privateRoutes = {
  HOME: '/',
  ORDER: '/order',
  SETTINGS: '/settings',
  SETTINGS_ID: '/settings/:id',
  CREATE_ORDER: '/create-order',
  ARCHIVE: '/archive',
  ORDER: '/order/:id',

  STATUSES: '/statuses/:slag',
  MANAGERS: '/managers/',
  MANAGERSORDER:'/managers-orders/',
  MANAGERDETAILINFO:'/manager/:id',
  REQUESTMANAGER:'/request-managers/',



  ALL_COMPANIES:'/companies/',
  COMPANI_ID:'/companies/:id/',
  COMPANI_MANAGER_INFO:'/companies/manager/:id/',
  


};
