import React, { useState, useRef } from 'react';
import s from './TableOrderRow.module.scss';
import {
  TableRow,
  TableCell,
  Box,
  Avatar,
  Typography,
  IconButton,
  Button,
  Menu,
  Popover,
} from '@material-ui/core';
import { CustomStatus } from '../../Order/CustomStatus/CustomStatus';
import { ArrowLeft } from './../../../assetc/svg/arrowLeft';
import { ArrowDown } from './../../../assetc/svg/arrowDown';
import storageService from '../../../utils/storageService';
import { useHistory, Link } from 'react-router-dom';
import { privateRoutes } from '../../../constants/routes';
import { useStore } from '../../../stores/stores';
import { useSnackbar } from 'notistack';
import localStorageKeys from '../../../constants/localStorageKeys';
import { SmallMenuCRUD } from '../../SmallMenuCRUD/SmallMenuCRUD';
import {
  hasStatusUnordered,
  hasStatusValuated,
} from '../../../constants/statuses';
import { CardManagerAll } from '../../Order/CardManagerAll/CardManagerAll';
import { ModalWindow } from '../../ModalWindow/ModalWindow';
import { Chat } from '../../Chat/Chat';

export const TableOrderRow = ({ ...props }) => {
  const {
    row,
    AuthUser,
    row: { header, main },
  } = props;
  const [open, setOpen] = React.useState(false);
  let history = useHistory();

  const { users, orders } = useStore();

  function infoMassege(variant, text) {
    enqueueSnackbar(text, { variant });
  }

  const [anchorEl, setAnchorEl] = React.useState(null);

  let opening = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const { enqueueSnackbar } = useSnackbar();

  function continueOrder() {
    storageService.set('continueOrderID', row.id);
    history.push(privateRoutes.CREATE_ORDER);
  }

  function onDelete() {
    Promise.resolve(orders.removeOrder(row.id))
      .then(function (res) {
        res.data.messages.map((item) =>
          infoMassege(item.status, item.text),
        );
        const userID = storageService.get(localStorageKeys.USER_ID);
        return users.fetchUser(userID);
      })
      .then((result) => {
        // history.push(privateRoutes.HOME);
      });
  }

  const onDeleteOrder = (orderId) => () => {
    Promise.resolve(orders.removeOrder(orderId))
      .then(function (res) {
        res.data.messages.map((item) =>
          infoMassege(item.status, item.text),
        );
        const userID = storageService.get(localStorageKeys.USER_ID);
        return users.fetchUser(userID);
      })
      .then((result) => {});
    handleClose();
  };

  const onEditOrder = (orderId) => () => {
    storageService.set('continueOrderID', orderId);
    history.push(privateRoutes.CREATE_ORDER);
    handleClose();
  };

  const copyOrder = (orderId) => () => {
    Promise.resolve(orders.copyOrder(orderId)).then((result) => {
      result.data.messages.map((item) =>
        infoMassege(item.status, item.text),
      );
    });

    handleClose();
  };

  const [anchorElPoper, setAnchorElPoper] = React.useState({});

  const handleClickPoper = (event, popoverId) => {
    setAnchorElPoper({
      openedPopoverId: popoverId,
      anchorEl: event.currentTarget,
    });
  };

  const handleClosePoper = () => {
    setAnchorElPoper({ openedPopoverId: null, anchorEl: null });
  };

  const openPoper = Boolean(anchorElPoper);

  // modall comment and file
  const [openModalChat, setOpenModalChat] = useState({
    isOpen: false,
  });

  const [modalChat, setModalChat] = React.useState({});
  const itemsRef = React.useRef([]);

  const handleOpenChat = (event, popoverId, nomenclature_id) => {
    setModalChat({
      openedPopoverId: popoverId,
      isOpen: true,
      anchorEl: event.currentTarget,
      nomenclature_id: nomenclature_id,
    });
    itemsRef.current[nomenclature_id].classList.add(s.hidden_newMessageMark)




  };
  const handleCloseChat = () => {
    setModalChat({
      openedPopoverId: null,
      isOpen: false,
      anchorEl: null,
    });
  };
console.log(header[0].has_unread_comments );

  return (
    <>
      <TableRow className={s.root}>
        <TableCell className={s.toggleTd}>
          {row.countNomenclatures > 1 && (
            <IconButton
              className={s.toggleClass}
              onClick={() => setOpen(!open)}
            >
              {open ? (
                <ArrowLeft size="24px" />
              ) : (
                <ArrowDown size="24px" />
              )}
            </IconButton>
          )}
        </TableCell>
        <TableCell rowSpan={open ? row.countNomenclatures : 1}>
          <div className={s.alignCenter}>{row.id}</div>
        </TableCell>
        <TableCell>{header[0].created}</TableCell>
        <TableCell>{header[0].nomenclature}</TableCell>
        <TableCell>{header[0].directions.name}</TableCell>
        <TableCell>{header[0].amount}</TableCell>
        <TableCell>{header[0].date}</TableCell>
        <TableCell>{header[0].address}</TableCell>
        <TableCell>
          <Box className={s.manger} display="flex">
            <Box mr={2}>
              <Avatar
                style={{
                  width: 40,
                  height: 40,
                  transition: '0.3s',
                }}
              />
            </Box>
            <Box>
              <Typography
                variant="body2"
                onClick={(e) => {
                  handleClickPoper(e, header[0].id);
                }}
                className={s.linkManager}
              >
                {header[0].manager.name}
              </Typography>
            </Box>

            <div>
              <Popover
                open={anchorElPoper.openedPopoverId === header[0].id}
                anchorEl={anchorElPoper.anchorEl}
                onClose={handleClosePoper}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
              >
                <Typography>
                  <Box>
                    <CardManagerAll
                      titleText="Менеджер напряму"
                      AuthUser={AuthUser}
                      manager={header[0].manager}
                    ></CardManagerAll>
                  </Box>
                </Typography>
              </Popover>
            </div>
          </Box>
        </TableCell>

        <TableCell>
          {/* {header.} */}
         
            <>
            <Button
            type="button"
            className={s.buttonViewsMessage}
            onClick={(e) => {
              handleOpenChat(e, 1, header[0].id);
            }}
          >
            Переглянути
            {header[0].has_unread_comments && (
              <div ref={el => itemsRef.current[header[0].id] = el} className={s.newMessageMark  }></div>
            )}
          </Button>
            </>
          
          

          {modalChat.openedPopoverId === 1 && (
            <ModalWindow
              open={
                modalChat.openedPopoverId === 1
                  ? modalChat
                  : modalChat
              }
              handleClose={handleCloseChat}
              titleWindow="Коментарі та файли"
            >
              <Chat
              AuthUser={AuthUser}
                nomenclature_id={modalChat.nomenclature_id}
              ></Chat>
            </ModalWindow>


          )}
        </TableCell>
        <TableCell>
          <CustomStatus status={row.status}></CustomStatus>
        </TableCell>

        <TableCell>
          <Box>
            <SmallMenuCRUD
              status={row.status}
              order={row}
              copyOrder={copyOrder}
              onDeleteOrder={onDeleteOrder}
              onEditOrder={onEditOrder}
              opening={opening}
              handleClick={handleClick}
              handleClose={handleClose}
              anchorEl={anchorEl}
            ></SmallMenuCRUD>
          </Box>
        </TableCell>
      </TableRow>
      {main.map((line, key) =>
        open ? (
          <TableRow key={key}>
            <TableCell></TableCell>
            {/* <TableCell></TableCell> */}
            {/* <TableCell><div className={s.alignCenter}>{line.seguence_number}</div></TableCell> */}
            <TableCell>{line.created}</TableCell>
            <TableCell>{line.nomenclature}</TableCell>
            <TableCell>{line.directions.name}</TableCell>
            <TableCell>{line.amount}</TableCell>
            <TableCell>{line.date}</TableCell>
            <TableCell>{line.address}</TableCell>

            <TableCell>
              <Box display="flex" className={s.manger}>
                <Box mr={2}>
                  <Avatar
                    style={{
                      width: 40,
                      height: 40,
                      transition: '0.3s',
                    }}
                  />
                </Box>
                <Box>
                  <Typography
                    variant="body2"
                    onClick={(e) => {
                      handleClickPoper(e, key);
                    }}
                    className={s.linkManager}
                  >
                    {line.manager.name}
                  </Typography>
                  <div>
                    <Popover
                      open={anchorElPoper.openedPopoverId === key}
                      anchorEl={anchorElPoper.anchorEl}
                      onClose={handleClosePoper}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                      }}
                    >
                      <Typography>
                        <Box>
                          <CardManagerAll
                            titleText="Менеджер напряму"
                            AuthUser={AuthUser}
                            manager={line.manager}
                          ></CardManagerAll>
                        </Box>
                      </Typography>
                    </Popover>
                  </div>
                </Box>
              </Box>
            </TableCell>

            <TableCell>
          
              <Button
                type="button"
                onClick={(e) => {
                  handleOpenChat(e, key + 2, line.id);
                }}
              >
                Переглянути

                {line.has_unread_comments && (
              <div ref={el => itemsRef.current[line.id] = el} className={s.newMessageMark  }></div>
            )}
              </Button>

              {modalChat.openedPopoverId === key + 2 && (
                <ModalWindow
                  open={
                    modalChat.openedPopoverId === key + 2
                      ? modalChat
                      : modalChat
                  }
                  handleClose={handleCloseChat}
                  titleWindow="Коментарі та файли"
                >
                  <Chat
                  AuthUser={AuthUser}
                    nomenclature_id={modalChat.nomenclature_id}
                  ></Chat>
                </ModalWindow>
              )}
            </TableCell>
            <TableCell>
              <CustomStatus status={row.status}></CustomStatus>
            </TableCell>
            <TableCell>
              <Box>
                <SmallMenuCRUD
                  status={row.status}
                  order={row}
                  copyOrder={copyOrder}
                  onDeleteOrder={onDeleteOrder}
                  onEditOrder={onEditOrder}
                  opening={opening}
                  handleClick={handleClick}
                  handleClose={handleClose}
                  anchorEl={anchorEl}
                ></SmallMenuCRUD>
              </Box>
            </TableCell>
          </TableRow>
        ) : (
          <></>
        ),
      )}

      {hasStatusValuated(row.status) ? (
        <TableRow>
          <TableCell colSpan="11" className={s.orderRegardedBox}>
            <div className={s.orderRegarded}>
              <Box>
                Готові розцінки по вашому замовленню. Ознайомтесь з
                деталями та продовжіть співпрацю.
              </Box>
              <Link to={`/order/${row.id}`}>
                <Button className={s.btn_continue}>
                  Переглянути деталі
                </Button>
              </Link>
            </div>
          </TableCell>
        </TableRow>
      ) : null}
      {hasStatusUnordered(row.status) ? (
        <TableRow>
          <TableCell className={s.foterInfoBox} colSpan="11">
            <div className={s.foterInfoLine}>
              <div className={s.foterInfoText}>
                У вас залишилось незавершене замовлення. Продовжіть
                роботу або видаліть його.
              </div>
              <Button onClick={onDelete} className={s.btn_delete}>
                Видалити
              </Button>
              <Button
                onClick={continueOrder}
                className={s.btn_continue}
              >
                Продовжити
              </Button>
            </div>
          </TableCell>
        </TableRow>
      ) : null}
    </>
  );
};
