import React from 'react';
import s from './Chat.module.scss';
import { useEffect } from 'react';
import * as Api from './../../api';
import { useState } from 'react';
import GetAppIcon from '@material-ui/icons/GetApp';
import DescriptionSharpIcon from '@material-ui/icons/DescriptionSharp';
import { Link } from 'react-router-dom';
import { Button } from '@material-ui/core';
import { FormManagerAnsver } from '../Form/FormManagerAnsver/FormManagerAnsver';
import { useStore } from '../../stores/stores';
import { propOr } from 'ramda';
import { getStatus } from '../../constants/statuses';

export const Chat = ({ ...props }) => {
  // const s = useStyles();
  const { users } = useStore();
  const AuthUser = users.authUser;
  const { nomenclature_id, statuses } = props;

  const [isFlag, setIsFlag] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [viewsStatus, setViewsStatus] = useState([ 'valuating', 'agreed', ]);
  const [viewsFile, setViewsFile] = useState(['doc','docx','pdf','ppt','pptx','xls','xlsx']);
  const [info, setInfo] = useState({ comments: [] });

  useEffect(() => {
    Promise.all([
      Api.getOrderedNomenclatureComments(nomenclature_id,AuthUser.id),
    ]).then((result) => {
       
      let api_comments = result[0].data.results;
      
      let infoStatus = [
        'valuating',
        'agreed',
      ];

      api_comments = api_comments.map((item) => {
       
        if(item.author.role === 'client'){
          item['title']="Коментар"
        }else if(item.author.role === 'manager' && (item.status!=null && viewsStatus.indexOf(item.status)!=-1)){
          item['title']=`Відповідь менеджера після статусу "${getStatus(item.status).text}"`
        }else if( item.author.role === 'manager' && item.status==null){
          item['title']=`Відповідь менеджера після статусу "Розцінки"`
        }
        
        if(!!item.file){
          let formatFile = item.file.split('.').pop();

          if(viewsFile.indexOf(formatFile) != -1){
            item['viewsFile']=true;
          }else{
            item['viewsFile']=false;
          }
        }
       
        if(item.author.role === 'manager'  && item.status!=null &&  viewsStatus.indexOf(item.status)!=-1 ){
          
          infoStatus = infoStatus.filter((n) => {return n != item.status});
        }else if(item.author.role === 'manager'  && item.status===null  ){
          infoStatus = infoStatus.filter((n) => {return n != 'valuating'});
           
        }
        return item;
      });

      setInfo({ comments: api_comments });

      console.log(infoStatus );
      
        if (AuthUser.role === 'manager'  &&  infoStatus.indexOf(statuses)!=-1 ) {
          setIsFlag(true);
        } else {
          setIsFlag(false);
        }

      setIsLoading(true);
    });
  }, []);
   
  return (
    <>
      {!!isLoading && (
        <>
          {info.comments.length > 0 ||
          (AuthUser.role === 'manager' &&
            viewsStatus.indexOf(statuses) != -1) ? (
            <div className="">
              <div className={s.group_wrap}>
                {info.comments.map((item) => (
                  <>
                    <div className={s.group}>
                      <div className={`${s.title} ${s.weight}`}>
                        {item.title}
                      </div>
                      <div className={s.author}>
                        {item.author.full_name}
                      </div>

                      <div className={s.main}>
                        <div className={s.text}>{item.text}</div>
                      </div>
                    </div>
                    <div className={s.group}>
                      <div className={s.title}>Файл</div>
                      <div className={s.main}>
                      {item.viewsFile === true && (
                        <a target="_blank" href={`https://docs.google.com/gview?url=${item.file}&embeddedocs.google.comd=true `}>
                          <Button
                            color="default"
                            className={s.button}
                            startIcon={<DescriptionSharpIcon />}
                          >
                            Переглянути
                          </Button>
                        </a>
                      )}
                        <a href={`${item.file}`}>
                          <Button
                            color="default"
                            className={s.button}
                            startIcon={<GetAppIcon />}
                          >
                            Викачати файл
                          </Button>
                        </a>
                      </div>
                    </div>
                  </>
                ))}
              </div>
              {!!isFlag && (
                  <div className={s.group_wrap}>
                    <div className={s.group}>
                      <div className={`${s.title} ${s.weight}`}>Написати</div>
                    </div>
                    <div className={s.main}>
                      <FormManagerAnsver
                        AuthUser={AuthUser}
                        nomenclature_id={nomenclature_id}
                        statuses={statuses}
                        info={info}
                        statuses={statuses}
                        setInfo={setInfo}
                        setIsFlag={setIsFlag}
                        viewsFile={viewsFile}
                        viewsStatus={viewsStatus}
                      ></FormManagerAnsver>
                    </div>
                  </div>
                )}
            </div>
          ) : (
            <div className={s.comentForm}>Коментарів не знайдено</div>
          )}
        </>
      )}
    </>
  );
};
