import React, { useState } from 'react';

import { isLoggedIn } from '../utils/general';
import { publicRoutes, privateRoutes } from '../constants/routes';
import { useHistory, Switch, Route } from 'react-router-dom';
import Login from './Login/Login';
import RegisterForm from './Register/Register';
import MainScreen from './Home/Home';
import { Headers } from '../components/Header/Header';
import s from './Home/Home.module.scss';
import { useStore } from '../stores/stores';
import storageService from '../utils/storageService';
// import { Loader } from '../components/Loader/Loader';
import { Loader } from '../components/Loader/Loader';
import { useEffect } from 'react';

export const Router = () => {
  const history = useHistory();
  const currentLocation = history.location.pathname;
  const [isOpen, SetOpen] = useState({ open: false });
  const [isLoading, setIsLoading] = useState(true);
  const { users } = useStore();
  function OpenMenu() {
    SetOpen({ open: !isOpen.open });
  }

  useEffect(() => {
    

    if (!isLoggedIn() && currentLocation !== publicRoutes.REGISTER) {
      history.push(publicRoutes.LOGIN);
      setIsLoading(false);
    } else {
      setIsLoading(false);
      if (!isLoggedIn()) {
        console.log(!isLoggedIn());
      } else {
        Promise.resolve(users.fetchUser(storageService.get('userId')))
          .then((result) => {
            if (result.status_code === 404) {
              storageService.clearStorage();
              history.push(publicRoutes.LOGIN);
              setIsLoading(false);
            } else {
              setIsLoading(false);
            }
          })
          .catch((res) => {
            // console.log(res);
            console.log('res');
            history.push(publicRoutes.LOGIN);
           
          });
      }
    }


  
  }, []);

  console.log('1', isLoading);

  return (
    <>
      {isLoading ? (
        <Loader></Loader>
      ) : (
        <div className={isOpen.open ? `${s.isOverflov}` : ''}>
          <Headers
            SetOpen={SetOpen}
            isOpen={isOpen}
            OpenMenu={OpenMenu}
          />
          <Switch>
            <Route
              path={publicRoutes.LOGIN}
              component={Login}
            ></Route>
            <Route
              path={publicRoutes.REGISTER}
              component={RegisterForm}
            ></Route>
            <Route
              path={privateRoutes.HOME}
              SetOpen={SetOpen}
              component={(props) => <MainScreen currentLocation={currentLocation} SetOpen={SetOpen} />}
            ></Route>
          </Switch>
        </div>
      )}
    </>
  );
};
