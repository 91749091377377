import * as Api from '../../../api';
import { propOr } from 'ramda';
import { getNomenclatureOrder } from '../../../api';

export const fetchDataNomenclatureTable = ({ ...props }) => {
  let fetchDataTable = props;

  let fetchParamsQuery = getUrl(fetchDataTable);

  return Api.paginatedOrderedNomenclatures(fetchParamsQuery).then(
    (result) => {
     
      let tableData = {};
      tableData.allCount = result.data.count;
      tableData.itemsPerPage = result.data.results.length;
      tableData.nomenclature = result.data.results.map((item) => {
        return getNomenclature(item);
      });

      return tableData;
    },
  );
};

function getNomenclature(item) {
 

  return {
    id: item.id,
    orderId: Number(item.order_id),
    amount: item.amount,
    date: item.date,
    created: item.created,
    address: item.address,
    price: item.price,
    nomenclature: item.nomenclature.name,

    directions: propOr('', ['name'], item.nomenclature.direction),
    lastPrice: item.last_orders.map((last_order) => {
      return {
        dataTime: last_order.datetime,
        price: last_order.price,
      };
    }),

    client: {
      fullName: item.client.full_name,
      companyName: propOr('','name',item.client.client_profile.company),
    },
    manager: propOr('','manager',item.nomenclature),
  };
}

function getUrl(data) {
  return Object.entries(data)
    .map(([key, val]) => {
      if (typeof val === 'object') {
        return `${encodeURIComponent(key)}=${JSON.stringify(val)}`;
      } else {
        return `${encodeURIComponent(key)}=${encodeURIComponent(
          val,
        )}`;
      }
    })
    .join('&');
}
