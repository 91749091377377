import React, { useState } from 'react';
import s from './TableOrderRowClient.module.scss';
import {
  TableRow,
  TableCell,
  IconButton,
  Box,
  Avatar,
  Typography,
  Button,
} from '@material-ui/core';
import { ArrowLeft } from '../../../assetc/svg/arrowLeft';
import { ArrowDown } from '../../../assetc/svg/arrowDown';
import { CustomStatus } from '../../Order/CustomStatus/CustomStatus';
import { propOr } from 'ramda';
import { SmallMenuCRUD } from '../../SmallMenuCRUD/SmallMenuCRUD';
import storageService from '../../../utils/storageService';
import { privateRoutes } from '../../../constants/routes';
import { useSnackbar } from 'notistack';
import { useStore } from '../../../stores/stores';
import { useHistory } from 'react-router-dom';
import localStorageKeys from '../../../constants/localStorageKeys';

import * as Api from '../../../api';
import { updateOrders } from '../../evaluationNomenclature/statusProcessingOrders/updateOrdersApi';
import { ModalWindow } from '../../ModalWindow/ModalWindow';
import { Chat } from '../../Chat/Chat';

export const TableOrderRowClient = ({ ...props }) => {
  const {
    tableData,
    filterValue,
    setTablerData,
    AuthUser,
    ManagerId,
    row,
    row: { header, main },
  } = props;
console.log(props );
console.log(AuthUser );

  const [open, setOpen] = useState(false);
  let history = useHistory();

  const { users, orders } = useStore();

  function infoMassege(variant, text) {
    enqueueSnackbar(text, { variant });
  }

  const [anchorEl, setAnchorEl] = React.useState(null);

  let opening = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const { enqueueSnackbar } = useSnackbar();

  function continueOrder() {
    storageService.set('continueOrderID', row.id);
    history.push(privateRoutes.CREATE_ORDER);
  }

  function onDelete() {
    Promise.resolve(orders.removeOrder(row.id))
      .then(function (res) {
        res.data.messages.map((item) =>
          infoMassege(item.status, item.text),
        );
        const userID = storageService.get(localStorageKeys.USER_ID);
        return users.fetchUser(userID);
      })
      .then((result) => {
        // history.push(privateRoutes.HOME);
      });
  }

  const onDeleteOrder = (orderId) => () => {
    Promise.resolve(Api.deleteOrder(orderId))
      .then(function (res) {
        res.data.messages.map((item) =>
          infoMassege(item.status, item.text),
        );
        handleClose();
        updateOrders(filterValue, setTablerData);
      })
       
     
  };

  const onEditOrder = (orderId) => () => {
    storageService.set('continueOrderID', orderId);
    history.push(privateRoutes.CREATE_ORDER);
    handleClose();
    
  };

  const copyOrder = (orderId) => () => {
    Promise.resolve(Api.copyOrder(orderId)).then((result) => {
      result.data.messages.map((item) =>
        infoMassege(item.status, item.text),
      );
    });

    handleClose();
    updateOrders(filterValue, setTablerData);
  };



  // modall comment and file
  const [openModalChat, setOpenModalChat] = useState({
    isOpen: false,
  });

  const [modalChat, setModalChat] = React.useState({});
  const handleOpenChat = (event, popoverId, nomenclature_id) => {
    setModalChat({
      openedPopoverId: popoverId,
      isOpen: true,
      anchorEl: event.currentTarget,
      nomenclature_id: nomenclature_id,
    });
  };
  const handleCloseChat = () => {
    setModalChat({
      openedPopoverId: null,
      isOpen: false,
      anchorEl: null,
    });
  };

  return (
    <>
      <TableRow className={s.root}>
        <TableCell className={s.toggleTd}>
          {row.ordered_nomenclatures.length > 1 && (
            <IconButton
              className={s.toggleClass}
              onClick={() => setOpen(!open)}
            >
              {open ? (
                <ArrowLeft size="24px" />
              ) : (
                <ArrowDown size="24px" />
              )}
            </IconButton>
          )}
        </TableCell>

        <TableCell rowSpan={open ? row.countNomenclatures : 1}>
          <div className={s.alignCenter}>{row.id}</div>
        </TableCell>
        <TableCell>{header[0].created}</TableCell>
        <TableCell>{header[0].nomenclature.name}</TableCell>
        <TableCell>
          {propOr('', 'name', header[0].nomenclature.direction)}
        </TableCell>
        <TableCell>{header[0].amount}</TableCell>
        <TableCell>{header[0].date}</TableCell>
        <TableCell>{header[0].address}</TableCell>
        <TableCell>
          <Box display="flex">
            <Box mr={2}>
              <Avatar
                style={{
                  width: 40,
                  height: 40,
                  transition: '0.3s',
                }}
              />
            </Box>
            <Box>
              <Typography variant="body2" className={s.fullName}>
                {header[0].full_name}
              </Typography>
            </Box>
          </Box>
        </TableCell>

        <TableCell>
          <Button
            type="button"
            onClick={(e) => {
              handleOpenChat(e, 1, header[0].id);
            }}
          >
            Переглянути
          </Button>

          {modalChat.openedPopoverId === 1 && (
            <ModalWindow
              open={
                modalChat.openedPopoverId === 1
                  ? modalChat
                  : modalChat
              }
              handleClose={handleCloseChat}
              titleWindow="Коментарі та файли"
            >
              <Chat
                AuthUser={AuthUser}
                nomenclature_id={modalChat.nomenclature_id}
              ></Chat>
            </ModalWindow>
          )}
        </TableCell>

        <TableCell>
          <CustomStatus status={row.status}></CustomStatus>
        </TableCell>

        <TableCell>
          <Box>
            <SmallMenuCRUD
              status={row.status}
              order={row}
              copyOrder={copyOrder}
              onDeleteOrder={onDeleteOrder}
              onEditOrder={onEditOrder}
              opening={opening}
              handleClick={handleClick}
              handleClose={handleClose}
              anchorEl={anchorEl}
            ></SmallMenuCRUD>
          </Box>
        </TableCell>
      </TableRow>
      {main.map((line, key) =>
        open ? (
          <TableRow key={key}>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell>{line.created}</TableCell>
            <TableCell>{line.nomenclature.name}</TableCell>
            <TableCell>
              {propOr(' ', 'name', line.nomenclature.direction)}
            </TableCell>
            <TableCell>{line.amount}</TableCell>
            <TableCell>{line.date}</TableCell>
            <TableCell>{line.address}</TableCell>

            <TableCell>
              <Box display="flex">
                <Box mr={2}>
                  <Avatar
                    style={{
                      width: 40,
                      height: 40,
                      transition: '0.3s',
                    }}
                  />
                </Box>
                <Box>
                  <Typography variant="body2" className={s.fullName}>
                    {line.full_name}
                  </Typography>
                </Box>
              </Box>
            </TableCell>
            <TableCell>
            
            <Button
              type="button"
              onClick={(e) => {
                handleOpenChat(e, key + 2, line.id);
              }}
            >
              Переглянути
            </Button>

            {modalChat.openedPopoverId === key + 2 && (
              <ModalWindow
                open={
                  modalChat.openedPopoverId === key + 2
                    ? modalChat
                    : modalChat
                }
                handleClose={handleCloseChat}
                titleWindow="Коментарі та файли"
              >
                <Chat
                AuthUser={AuthUser}
                  nomenclature_id={modalChat.nomenclature_id}
                ></Chat>
              </ModalWindow>
            )}
          </TableCell>
            <TableCell>
              <CustomStatus status={row.status}></CustomStatus>
            </TableCell>
            <TableCell></TableCell>
          </TableRow>
        ) : (
          <></>
        ),
      )}
    </>
  );
};
