import React from 'react';
// import s from './TextareaTextareaInput.module.scss';
import './TextareaInput.css';

export const TextareaInput = ({
  field,
  form: { touched, errors },
  ...props
}) => {
  return (
    <>
      <div className="TextareaInput">
        <div className={`TextareaInput__box  `}>
          <textarea
            className={`TextareaInput_field ${
              touched[field.name] && errors[field.name]
                ? 'TextareaInput__box_error'
                : ''
            } `}
            autoComplete="off"
            
            type="text"
            {...field}
            {...props}
          />
        </div>

        {touched[field.name] && errors[field.name] && (
          <div className="TextareaInput_error">
            {errors[field.name]}
          </div>
        )}
      </div>
    </>
  );
};
