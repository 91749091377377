import React, { useContext, useRef, forwardRef } from 'react';
import s from './firstStepOrder.module.scss';
import { Box, Button, Typography } from '@material-ui/core';
import { CardManagerOneStep } from '../CardManagerOneStep/CardManagerOneStep';
import * as Yup from 'yup';
import moment from 'moment';

// import { formatDistance } from 'date-fns'
// Require Esperanto locale
import { Form, Formik, Field, useFormikContext } from 'formik';
// choose your lib
import { CustomInput } from '../../Form/Elements/input/input';

import * as Api from '../../../api';
import { useStore } from '../../../stores/stores';
import CreateInfoOrder from '../CreateOrder/CreateInfoOrderContext';

import InputDataPicer from '../../InputDataPicer/InputDataPicer';

import { CustomSelect } from '../../CustomSelect/CustomSelect';
import { CustomSelectAsync } from '../../CustomSelectAsync/CustomSelectAsync';
import { propOr } from 'ramda';
import { TextareaInput } from '../../Form/Elements/TextareaInput/TextareaInput';
import { FileUpload } from '../fileUpload/fileUpload';
import { useState } from 'react';
// import { getSnapshot } from 'mobx-state-tree';
export const OneStepOrder = ({ ...props }) => {
  // const [selectedDate, handleDateChange] = useState(new Date());
  let days = 10;
  var minData = new Date(Date.now() + days * 24 * 60 * 60 * 1000);
  const [uploadFile, setUploadFile] = useState(null);

  const [selectedDate, setSelectedDate] = React.useState({
    selectData: minData,
    minData: minData,
  });

  const { users } = useStore();

  const { setOrderInfo, ...orderInfo } = useContext(CreateInfoOrder);

  const { directions, nomenclature, newOrder, manager } = orderInfo;

  const {
    onDirections,
    onNomenclature,
    setActiveStep,
    AuthUser,
  } = props;

  const initialValues = {
    directions: '',
    nomenclature: '',
    count: '',
    date: selectedDate.selectData,
    deliveri_addres: '',
  };

  const validationSchema = Yup.object().shape({
    directions: Yup.string(),

    nomenclature: Yup.string().required(
      "Поле обов'язкове для заповнення ",
    ),

    count: Yup.number()
      .typeError('Введіть кількість в числовому форматі')
      .min(0.1, 'Мінімальна кількість замовлення 0.1')

      .required("Поле обов'язкове для заповнення "),
    date: Yup.string().required("Поле обов'язкове для заповнення "),
    deliveri_addres: Yup.string().required(
      "Поле обов'язкове для заповнення ",
    ),
  });

  async function onSubmit(value) {
    const clientProfileUser = users.authUser.client_profile.id;
    const formValue = value;
    Promise.resolve(Api.createOrder(clientProfileUser))
      .then((results) => {
        const OrderId = results.data.id;

        let nomenclatureObject = {
          order_id: OrderId,
          nomenclature_id: nomenclature.value.id,
          amount: value.count,
          address: value.deliveri_addres,
          date: moment(new Date(value.date)).format('DD.MM.YYYY'),
        };
        return Api.addOrderedNomenclatures(nomenclatureObject);
      })
      .then((results) => {
        const file = new FormData();
        file.append('file', !!uploadFile?uploadFile:'');
        file.append('author_id', users.authUser.client_profile.id);
        file.append('ordered_nomenclature', results.data.id);

        let comment = {
          author_id: users.authUser.client_profile.id,
          text: formValue.comment,
          ordered_nomenclature: results.data.id,
        };
        
        Promise.all([
          Api.addOrderedNomenclatureComments(comment),
          Api.addOrderedNomenclatureFiles(file),
        ]).then((result) => {
           
        });
         
      //   return results;
         
      // })
      // .then((results) => {
        

        
        setActiveStep(1);

        setOrderInfo({
          ...orderInfo,
          directions: {
            ...orderInfo.directions,
            value: [],
          },
          nomenclature: {
            ...orderInfo.nomenclature,
            value: [],
          },
          newOrder: {
            ...orderInfo.newOrder,
            orderID: results.data.order_id,
            nomenclatures: newOrder.nomenclatures.concat(
              results.data,
            ),
          },
        });
      });
  }

  return (
    <>
      <Box className={s.first_order}>
        <Box className={s.first_order__sections}>
          <Box>
            <Formik
              initialValues={initialValues}
              onSubmit={onSubmit}
              validationSchema={validationSchema}
            >
              <Form>
                <div className={s.Form_wrap}>
                  <div>
                    <Typography
                      className={s.InpunTitle}
                      variant="body1"
                    >
                      Напрям номенклатури
                    </Typography>

                    <Field
                      name="directions"
                      id="directions"
                      directions={directions}
                      onDirections={onDirections}
                      component={CustomSelect}
                    ></Field>
                  </div>
                  <div>
                    <Typography
                      className={s.InpunTitle}
                      variant="body1"
                    >
                      Номенклатура
                    </Typography>
                    <Field
                      name="nomenclature"
                      id="nomenclature"
                      direction_id={propOr(
                        '',
                        ['itemId'],
                        orderInfo.directions.value,
                      )}
                      nomenclature={propOr(
                        '',
                        ['value'],
                        orderInfo.nomenclature,
                      )}
                      onNomenclature={onNomenclature}
                      component={CustomSelectAsync}
                    ></Field>
                  </div>
                  <div>
                    <Typography
                      className={s.InpunTitle}
                      variant="body1"
                    >
                      Кількість номенклатури
                    </Typography>

                    <Field
                      placeholder="Введіть кількість номенклатури"
                      name="count"
                      id="count"
                      type="text"
                      component={CustomInput}
                    />
                  </div>
                  <div>
                    <Typography
                      className={s.InpunTitle}
                      variant="body1"
                    >
                      Дата поставки
                    </Typography>
                    <Field
                      name="date"
                      id="date"
                      selectedDate={selectedDate}
                      setSelectedDate={setSelectedDate}
                      component={InputDataPicer}
                    />
                    {/* <InputDataPicer selectedDate={selectedDate} setSelectedDate={setSelectedDate}></InputDataPicer> */}
                  </div>

                  <div>
                    <Typography
                      className={s.InpunTitle}
                      variant="body1"
                    >
                      Адреса поставки
                    </Typography>

                    <Field
                      placeholder="Введіть адресу поставки"
                      name="deliveri_addres"
                      id="deliveri_addres"
                      type="text"
                      autoComplete="true"
                      component={CustomInput}
                    />
                  </div>
                  <div>
                    <Typography
                      className={s.InpunTitle}
                      variant="body1"
                    >
                      Коментар
                    </Typography>

                    <Field
                      placeholder="Введіть коментар"
                      name="comment"
                      id="comment"
                      maxlength="520"
                      // as="textarea"
                      // autoComplete="true"
                      component={TextareaInput}
                    />
                  </div>
                  <div>
                    <Typography
                      className={s.InpunTitle}
                      variant="body1"
                    >
                      Файл
                    </Typography>
                    <FileUpload
                      setUploadFile={setUploadFile}
                      uploadFile={uploadFile}
                    />
                  </div>
                </div>
                <div className={s.butoon__order_wrap}>
                  <Button className={s.butoon__order} type="submit">
                    Створити замовлення
                  </Button>
                </div>
              </Form>
            </Formik>
          </Box>
        </Box>
        <Box className={s.first_order__sections}>
          <CardManagerOneStep
            titleText="Ваш менеджер"
            AuthUser={AuthUser}
            manager={manager}
          ></CardManagerOneStep>
        </Box>
      </Box>
    </>
  );
};
