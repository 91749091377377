import { propOr } from "ramda";

const { fetchDataOrders } = require("../tableOrderManager/fetchTableData");

export const updateOrders = (filterValue, setTablerData) => {
  console.log(filterValue );
    let filterValueNomenclature = {
     
      
      current_user_id:propOr('', 'current_user_id', filterValue),
      client_profile_id: propOr('', 'client_profile_id', filterValue),
      company_id: propOr('', 'id', filterValue.company_id),
      date: propOr('', 'date', filterValue.date),
      // date_to: propOr('', 'date', filterValue.date_to),
      // date_from: propOr('', 'date', filterValue.date_from),
      date_from: filterValue.date_from,
      date_to: filterValue.date_to,
      direction_id: propOr('', 'id', filterValue.direction_id),
      page_number: filterValue.page_number,
      page_size: filterValue.page_size,
      statuses: filterValue.statuses,
      manager_profile_id: filterValue.manager_profile_id,
      manager_profile_ids: !!filterValue.manager_profile_ids?filterValue.manager_profile_ids:[],
    };
  
    Promise.resolve(fetchDataOrders(filterValueNomenclature)).then(
      (result) => {
        setTablerData(result);
      },
    );
  }