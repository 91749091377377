import React, { useContext, useState } from 'react';
import s from './CreateOrderProduct.module.scss';
import { Button, Typography } from '@material-ui/core';
import { CustomInput } from '../Elements/input/input';
import Select from 'react-select';
import * as Yup from 'yup';
import moment from 'moment';
import { Form, Formik, Field } from 'formik';

import CreateInfoOrder from '../../Order/CreateOrder/CreateInfoOrderContext';
import * as Api from '../../../api';
import InputDataPicer from '../../InputDataPicer/InputDataPicer';
import { useEffect } from 'react';
import { CustomSelectAsync } from '../../CustomSelectAsync/CustomSelectAsync';
import { propOr } from 'ramda';
import { FileUpload } from '../../Order/fileUpload/fileUpload';
import { TextareaInput } from '../Elements/TextareaInput/TextareaInput';

export const CreateOrderProduct = ({ ...props }) => {
  const { setOrderInfo, ...orderInfo } = useContext(CreateInfoOrder);
  const { directions, nomenclature, newOrder, manager } = orderInfo;
  const [uploadFile, setUploadFile] = useState(null);

  const {
    AuthUser,
    orderId,
    onNomenclature,
    onDirections,
    handleClose,
  } = props;

  let days = 10;
  var minData = new Date(Date.now() + days * 24 * 60 * 60 * 1000);

  const [selectedDate, setSelectedDate] = React.useState({
    selectData: minData,
    minData: minData,
  });

  const initialValues = {
    directions: '',
    nomenclature: '',
    count: '',
    date: selectedDate.selectData,
    deliveri_addres: '',
  };

  useEffect(() => {
    if (!!orderId) {
      let infoNomenclature = newOrder.nomenclatures.filter(
        (item) => item.id === orderId,
      );

      var pattern = /(\d{2})\.(\d{2})\.(\d{4})/;
      var dt = new Date(
        infoNomenclature[0].date.replace(pattern, '$3-$2-$1'),
      );

      var UTCstring = dt.toUTCString();
      initialValues.count = infoNomenclature[0].amount;
      initialValues.deliveri_addres = infoNomenclature[0].address;
      initialValues.date = UTCstring;

      setSelectedDate({ ...selectedDate, selectData: UTCstring });

      initialValues.nomenclature =
        infoNomenclature[0].nomenclature.code;

      let directionValue = [];
      let filterNomenclature = [];
      let nomenclaturesValue;

      nomenclaturesValue = {
        name: `${infoNomenclature[0].nomenclature.name}`,
        label: `${infoNomenclature[0].nomenclature.name}`,
        value: infoNomenclature[0].nomenclature.name,
        nomenclatureID: infoNomenclature[0].nomenclature.id,
        direction: {
          code:
            infoNomenclature[0].direction !== undefined
              ? infoNomenclature[0].direction.code
              : null,
        },
        id: infoNomenclature[0].nomenclature.id,
        manager: {
          code:
            infoNomenclature[0].manager !== undefined
              ? infoNomenclature[0].manager.code
              : null,
        },
      };

      if (!infoNomenclature[0].nomenclature.direction) {
        directionValue = [{ label: '', value: '' }];
      } else {
        directionValue = orderInfo.directions.list.filter(
          (item) =>
            item.value ===
            infoNomenclature[0].nomenclature.direction.code,
        );
      }

      setOrderInfo({
        ...orderInfo,
        nomenclature: {
          ...orderInfo.nomenclature,
          value: nomenclaturesValue,
          // tempList: filterNomenclature,
          chengNomeclature: true,
        },
        directions: {
          ...orderInfo.directions,
          value: directionValue[0],
        },
      });
    } else {
      setOrderInfo({
        ...orderInfo,
        nomenclature: {
          ...orderInfo.nomenclature,
          value: [],
          tempList: orderInfo.nomenclature.list,
          chengNomeclature: true,
        },
        directions: {
          ...orderInfo.directions,
          value: [],
        },
      });
    }
  }, []);

  const validationSchema = Yup.object().shape({
    directions: Yup.string(),
    nomenclature: Yup.string().required(
      "Поле обов'язкове для заповнення ",
    ),
    count: Yup.number()
      .typeError('Введіть кількість в числовому форматі')
      .required("Поле обов'язкове для заповнення "),
    date: Yup.string().required("Поле обов'язкове для заповнення "),
    deliveri_addres: Yup.string().required(
      "Поле обов'язкове для заповнення ",
    ),
  });

  async function onSubmit(value) {
    let nomenclatureObject = {
      order_id: newOrder.orderID,
      nomenclature_id: nomenclature.value.id,
      amount: value.count,
      address: value.deliveri_addres,
      date: moment(new Date(value.date)).format('DD.MM.YYYY'),
    };
console.log(AuthUser );

    
    
   

    if (!!orderId) {
      nomenclatureObject.nomenclatureOrderOld = orderId;

      return Api.chengeNomenclatureOrdered(nomenclatureObject).then(
        (results) => {
          let updateNomenclatures = orderInfo.newOrder.nomenclatures.map(
            (item) => {
              if (item.id === orderId) {
                return results.data;
              } else {
                return item;
              }
            },
          );

          setOrderInfo({
            ...orderInfo,
            newOrder: {
              ...orderInfo.newOrder,
              orderID: results.data.order_id,
              nomenclatures: updateNomenclatures,
            },
          });

          handleClose();
        },
      );
    } else {
      return Api.addOrderedNomenclatures(nomenclatureObject).then(
        (results) => {
          console.log('nomenclatureObject',results.data.id );
          
          const file = new FormData();
          file.append('file', !!uploadFile?uploadFile:'');
          file.append('author_id', AuthUser.client_profile.id);
          file.append('ordered_nomenclature', results.data.id);
      
          let comment = {
            author_id: AuthUser.client_profile.id,
            text: value.comment,
            ordered_nomenclature: results.data.id,
          };

          Promise.all([
            Api.addOrderedNomenclatureComments(comment),
            Api.addOrderedNomenclatureFiles(file),
          ]).then((result) => {
             
          });
          setOrderInfo({
            ...orderInfo,
            newOrder: {
              ...orderInfo.newOrder,
              orderID: results.data.order_id,
              nomenclatures: newOrder.nomenclatures.concat(
                results.data,
              ),
            },
          });

          handleClose();
        },
      );
    }
  }

  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        className={s.Form_wrap}
      >
        <Form>
          <div className={s.Form_wrap}>
            <div>
              <Typography className={s.InpunTitle} variant="body1">
                Напрям номенклатури
              </Typography>

              <Select
                className={s.input}
                fullWidth
                placeholder="Виберіть напрям"
                options={directions.list}
                isLoading={directions.isLoading}
                onChange={onDirections}
                value={directions.value}
                name="directions"
                id="directions"
              />
            </div>
            <div>
              <Typography className={s.InpunTitle} variant="body1">
                Номенклатура
              </Typography>

              <Field
                name="nomenclature"
                id="nomenclature"
                direction_id={propOr(
                  '',
                  ['itemId'],
                  orderInfo.directions.value,
                )}
                nomenclature={propOr(
                  '',
                  ['value'],
                  orderInfo.nomenclature,
                )}
                onNomenclature={onNomenclature}
                component={CustomSelectAsync}
              ></Field>
            </div>
            <div>
              <Typography className={s.InpunTitle} variant="body1">
                Кількість номенклатури
              </Typography>

              <Field
                placeholder="Введіть кількість номенклатури"
                name="count"
                id="count"
                type="text"
                component={CustomInput}
              />
            </div>
            <div>
              <Typography className={s.InpunTitle} variant="body1">
                Дата поставки
              </Typography>
              <Field
                name="date"
                id="date"
                selectedDate={selectedDate}
                setSelectedDate={setSelectedDate}
                component={InputDataPicer}
              />
            </div>

            <div>
              <Typography className={s.InpunTitle} variant="body1">
                Адреса поставки
              </Typography>

              <Field
                placeholder="Введіть адресу поставки"
                name="deliveri_addres"
                id="deliveri_addres"
                type="text"
                autoComplete="true"
                component={CustomInput}
              />
            </div>
            {!orderId && (
              <>
               <div>
              <Typography className={s.InpunTitle} variant="body1">
                Коментар
              </Typography>

              <Field
                placeholder="Введіть коментар"
                name="comment"
                id="comment"
                maxlength="520"
                // as="textarea"
                // autoComplete="true"
                component={TextareaInput}
              />
            </div>
            <div>
              <Typography className={s.InpunTitle} variant="body1">
                Файл
              </Typography>
              <FileUpload
                setUploadFile={setUploadFile}
                uploadFile={uploadFile}
              />
            </div>
              </>
            )}
           
          </div>
          <div className={s.butoon__order_wrap}>
            <Button className={s.butoon__order} type="submit">
              {!!orderId ? (
                <>Редагувати замовлення</>
              ) : (
                <>Створити замовлення</>
              )}
            </Button>
          </div>
        </Form>
      </Formik>
    </>
  );
};
