import React from 'react';
import s from './managerViewsOrders.module.scss';
import { useState } from 'react';
import { Box, Typography } from '@material-ui/core';
import { useStore } from '../../../stores/stores';
import { PriceNomenclatures } from '../priceNomenclatures/priceNomenclatures';
import { StatusProcessingOrders } from '../statusProcessingOrders/statusProcessingOrders';
import { Loader } from '../../Loader/Loader';

export const ManagerViewsOrders = ({ ...props }) => {
  const { users } = useStore(); 
  const AuthUser = users.authUser;

  const statuses = props.match.params.slag;

  const [filterOrder, setFilterOrder] = useState({});
 
  return (
    <>
      {statuses == 'valuating' ? (
        <>
           
            <Box my={6} mx={4} className={s.FilterWrapBox}>
              <Typography className={s.MainTitle} variant="h4">
                {statusOrder[statuses]}
              </Typography>
              <Box className={s.tableWrap}>
                <Box className={s.tableBox}>
                  <PriceNomenclatures
                    AuthUser={AuthUser}
                    statuses={statuses}
                     
                  ></PriceNomenclatures>
                </Box>
              </Box>
            </Box>
          
        </>
      ) : (
        <>
            
            <Box my={6} mx={4}>
              <Typography className={s.MainTitle} variant="h4">
              {statusOrder[statuses]}
              </Typography>
              <Box className={s.tableWrap}>
                <Box className={s.tableBox}>
                  <StatusProcessingOrders
                    AuthUser={AuthUser}
                    statuses={statuses}
                   
                  ></StatusProcessingOrders>
                </Box>
              </Box>
            </Box>
           
        </>
      )}
    </>
  );
};

let statusOrder= {
 
  valuated:'Розцінені замовлення',
  agreed_without_contract:'Замовлення з очікуванням на договір',
  contract_sent:'Замовлення з відправленим договором',
  contract_concluded:'Замовлення з укладеним договором',
  agreed:'Погоджені замовлення',
  in_progress:'Виконуванні замовлення ',
  archive:'Архів замовленнь ',
}